.pagination {
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 0; 
    margin-top: 20px;
    list-style: none; 
    padding: 0; 
  }
  
  .page-item {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    margin: 0 3px; 
    padding: 5px 8px; 
    border: 1px solid #ddd; 
    border-radius: 4px; 
    cursor: pointer; 
    background-color: #f8f9fa; 
    color: #333; 
    font-weight: 500; 
    transition: all 0.3s ease; 
  }
  
  .page-item:hover {
    background-color: #e2e6ea; 
    border-color: #ccc; 
  }
  
  .page-item.active {
    background-color: #007bff; 
    color: white; 
    border-color: #007bff; 
  }
  
  .page-item.disabled {
    cursor: not-allowed; 
    color: #aaa; 
    background-color: #f8f9fa; 
    border-color: #ddd; 
  }
  
  .ellipsis {
    display: flex; 
    align-items: center; 
    padding: 8px 12px; 
    color: #999; 
  }
  