@import '../../../Styles/Colors.scss';

.add-campus-modal {
    background-color: $backgroundLightColor;
    border-radius: 20px 20px 10px 10px;
    font-size: 14px;

    .modal-header {
        background-image: $primaryGradient;
        color: $selectionColor;
        font-size: 20px;
        border-radius: 10px 10px 0px 0px;
    }

    .modal-body {
        padding: 0px !important;
    }

    .form-group {
        margin-bottom: 5px;
    }

    .small-card {
        position: relative;
        background-color: $backgroundColor;
        border-radius: 5px;
        margin-top: 10px;
        padding: 10px;

        .remove-icon {
            cursor: pointer;
            position: absolute;
            top: -10px;
            right: -10px;
        }
    }

    .buildings-list {
        max-height: 150px;
        overflow: auto;
    }

    .tile {
        padding: 10px 1.5rem;
    }

    .separator {
        height: 1px;
        background-color: $borderColor;
    }

    .invalid-text {
        font-size: 12px;
        color: $dangerColor;
    }
}