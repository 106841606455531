@import '../../Styles/Colors.scss';

.facility-locations {
    height: 100%;
    display: flex;
    flex-direction: column;

    .campus-name {
        font-size: 18px;
        padding-top: 20px;
        font-family: 'Roboto-Medium', sans-serif !important;
        flex-shrink: 0;
    }

    .cards-container {
        height: 100%;
        padding: 1rem 0;
        max-height: calc(100% - 74px);

        .custom-card {
            margin-bottom: 10px;
            border-radius: 5px;
            overflow: hidden;

            .card-header {
                background: $backgroundLightColor;
                color: $textColor;
                font-size: 16px;
                padding: 0px .75rem;
                font-family: 'Roboto-Medium', sans-serif !important;

                img {
                    margin-right: .75rem;
                }
            }

            .custom-card-body {
                overflow: auto;

                .list-group {
                    border: 0px;
                }

                .list-group-item:first-child {
                    border-radius: 0px;
                    border-top: 0px;
                }

                .list-group-item {
                    cursor: pointer;
                    border-width: 1px 0px 1px 0px;
                    border-style: solid;
                    border-color: $borderColor;
                }
            }
        }
    }

    .card-header-container {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 10px 0px;
    }

    .icons-container {

        span {
            margin-left: 20px !important;
        }

        span:first-child {
            margin-left: 10px !important;
        }
    }


    // ground-floor special case
    .ground-floor {
        height: 65px;
        display: flex;
        align-items: center;

        .url {
            display: none !important;
        }

        .icons-container {
            display: none;
        }
    }

    .locations-list {
        .list-group-item {
            cursor: default;
        }
    }
}