@import '../../Styles/Colors.scss';
@import '../../Styles/Fonts.scss';

.banner-settings {
    padding-bottom: 40px;
    overflow: hidden;

   
    .draggable-item {
        transition: transform 0.3s ease;
        background-color: white; 
        border: 1px solid #ddd; 
    }

    .draggable-item.selected {
        background-color: #4CAF50; 
        border: 1px solid #357635; 
        transform: scale(1.1); 
    }

    .title {
        margin-top: 40px;
        font-family: 'Roboto-Medium', sans-serif !important;
    }

    .other-settings-panel {
        padding: 5px 0px;
        font-size: 14px;
        background-color: $backgroundLightColor;
        overflow: hidden;

        .disabled {
            cursor: auto !important;
          }
        

        .settings-element {
            padding: 12px 12px;
        }
        
        .hint-text {
            @extend .RobotoLight;
            color: $textLightColor;
            font-size: 12px;
            line-height: 6px;
            white-space: pre-line;
            padding-left: 2px;
        }
        
        .text-field{
            position: relative;
            display: flex;
            max-height: fit-content;
            padding-bottom: 10px;
        }
        .add-button{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 10px;
        }
        .btn-cancel {
            height: 35px !important;
            font-size: 14px !important;
            padding: 0px 15px !important;
            box-shadow: none !important;
            margin-left: 10px;
        }
        .image-back{
            height: 120px;
            width: 120px;
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            .image-preview{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .outputContainer-display{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px 10px 10px;
            cursor: move;
            overflow: hidden;

        .id {
            color: solid black;
            font-weight: bold;
            padding-right: 20px;
        }
        
        .image-box{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color:#F2F2F3;
            border:3px solid gray;
            width: 440px;
            height: 240px;
            overflow: hidden;
        }

        .image-box img{
            object-fit: scale-down;
            width: 100%;
            height: 100%;
            margin: none;
            background-color: none;
            margin-top: none;
        }
        
        .image-display{
            object-fit: scale-down;
            width: 100%;
            height: 100%;
            margin: none;
            background-color: none;
            margin-top: none;
        }
    
        .url{
            height:200px;
            width: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 25px;
            padding-right: 10px;
        }
        .delete-button{
            color: white;
        }
        .hamburger-button{
            justify-content: right;
            align-items: right;
            cursor: move;

        }
        }
    }
}

