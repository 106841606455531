@import '../../../Styles/Colors.scss';

.custom-card {
    min-height: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-width: 0px;
    background-color: $backgroundLightColor;

    .custom-card-header {
        padding: 0px 1.25rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        font-family: 'Roboto-Medium';
        border-bottom: 1px solid $borderColor;
        cursor: default;

        img {
            margin-right: 10px;
        }
    }

    .custom-card-body {
        height: 100%;
        width: 100%;
        padding: 0px;
    }

}