 @import '../../../Styles/Colors.scss';

 .popup-modal {
     background-color: $backgroundLightColor;
     border-radius: 20px 20px 10px 10px;

     .modal-header {
         background-image: $primaryGradient;
         color: $selectionColor;
         font-size: 20px;
         line-height: 18px;
         border-radius: 10px 10px 0px 0px;
     }

     .modal-body {
         font-size: 14px;

         button {
             margin-left: 5px;
         }
     }
     .popup-body-extended{
        align-items: flex-start !important;
     }
     .remark-input{
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
     }
     .popup-body-extended .d-flex.mt-3{
        margin-left: auto;
        margin-right: auto;
     }
 }