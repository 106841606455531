.display-inputs {

    .input-group-text {
        padding: 0px 6px;
        font-size: .875rem;
        border-radius: 4px 0px 0px 4px;
    }

    .invalid-feedback {
        margin-top: 0px;
    }
}