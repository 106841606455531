@import './Styles/Colors.scss';
@import './Styles/Fonts.scss';

body {
    font-family: 'Roboto-Regular' !important;
}

.margin-inline-start-auto {
    margin-inline-start: auto !important;
}

// spinner
.spinner-border {
    width: 100px !important;
    height: 100px !important;
    border-width: 10px !important;
    color: $primaryColor !important;
    margin: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.color-primary-dark {
    color: $primaryColor;
}

// btns
.btn-secondary,
.btn-outline-secondary {
    height: 35px !important;
    font-size: 14px !important;
    padding: 0px 20px !important;
    box-shadow: none !important;
    border-color: $primaryColor !important;
}

.btn-secondary {
    background-color: $primaryColor !important;
}

.btn-outline-secondary {
    color: $primaryColor !important;
}

.btn-outline-secondary:hover {
    background-color: $backgroundLightColor !important;
}

.modal-header {
    padding: 1rem 1.5rem !important;
}

.modal-body {
    padding: 1rem 1.5rem !important;
}

.form-control.is-invalid {
    background: $backgroundLightColor !important;
    padding: .25rem .5rem;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeholderColor !important;
    opacity: 1 !important;
    font-size: 14px;
    /* Firefox */
}

// error modal : network disconnected
.network-disconnected-body {
    text-align: center;
    font-size: 20px !important;
}


.tooltip {
    max-width: 50%;
    opacity: 1 !important;
    font-family: inherit !important;

    .tooltip-inner {
        max-width: 100% !important;
        word-break: break-all;
        text-align: inline-start;
    }
}

/*

.btn-secondary {
    font-size: 15px !important;
    min-width: 120px;
    border-radius: 20px !important;
    padding: 8px 20px !important;
    background-image: $primaryGradient !important;
    border-color: $primaryGradient !important;
}

.btn-secondary:hover {
    background-image: $primaryColor !important;
}

.btn-outline-secondary {
    font-size: 15px !important;
    min-width: 120px;
    border-radius: 20px !important;
    border-color: $primaryGradient !important;
    color: $primaryGradient !important;
}

.btn-outline-secondary:hover {
    background-color: $primaryGradient !important;
    color: $textColor !important;
}

.btn-info {
    border-radius: 20px !important;
    color: $textColor !important;
    background-color: $textColor !important;
}

.btn-info:hover {
    color: $textColor !important;
    background-color: $backgroundColor !important;
}


.btn-primary {
    color: black !important;
    background-color: white !important;
    border-color: white !important;
    border-radius: 20px !important;
    padding: 5px 20px !important;
    font-size: 15px !important;
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, .4) !important;
}
*/