$primaryGradient: linear-gradient(135deg, #5D74ED 0%, #3A4784 100%);
$primaryColor: #485CC2;
$backgroundColor: #E5EAF5;
$editSelectionColor: #F2F6FC;
$placeholderColor: #999999;
$backgroundLightColor: white;
$selectionColor: white;
$borderColor: #E0E2EF;
$transparentColor: transparent;
$textColor: #333333;
$textLightColor: #6D6D72;
$dangerColor: #DC3545;

:export {
    primaryColor: $primaryColor;
    selectionColor: $selectionColor;
    textLightColor: $textLightColor;
}