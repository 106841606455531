@import '../../Styles/Colors.scss';
@import '../../Styles/Fonts.scss';

.settings {
    padding-bottom: 40px;

    .title {
        margin-top: 40px;
        font-family: 'Roboto-Medium', sans-serif !important;
    }

    .custom-list {
        min-height: 300px;

        .list-group-item {

            .link-name {
                color: $textColor;
            }

            .link-url {
                color: $textLightColor;
                font-family: 'Roboto-Light', sans-serif !important;
            }
        }
    }

    .other-settings-panel {
        padding: 5px 0px;
        font-size: 14px;
        background-color: $backgroundLightColor;

        .settings-element {
            position: relative;
            display: flex;
            padding: 12px 20px;

            .input-label {
                margin-right: 50px;

                .hint-text {
                    @extend .RobotoLight;
                    color: $textLightColor;
                    font-size: 12px;
                    line-height: 16px;
                    white-space: pre-line;
                }
            }

            .input-container {
                margin-right: 10px;
                display: inline-flex;
                align-items: center;
                flex-shrink: 1;
                padding: 0px !important;
                overflow:auto;
               

                .input-value{
                    margin-right: 4px;
                    align-items: center;
                    padding-top: 5px;
                }
            }
                .input-unit {
                    font-size: 11px;
                    margin-top: 3px;
                    @extend .RobotoLight;
                    color: $textLightColor;
                    display: inline-block; 
                    padding-top: 5px;
                    padding-left: 3px;
                }

                input {
                    width: 180px;
                    padding: .25rem;
                    text-align: center;
                }
            }    

            .img-container {
                position: absolute;
                right: 20px;
                top:auto;
                width: 60px;
                flex-shrink: 0;
                text-align: right;
                color: $primaryColor;

                svg {
                    margin-left: 5px;
                }
            }
            .react-switch-bg{
                height: 17px !important;
                width:40px !important;
            }
            .react-switch-handle {
                height: 13px !important;
                width: 13px !important;
            }
        
    }
    
}



/* .settings {

    .circular-div {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: $primaryColor;
        margin: 10px;
    }

    .list-group-item {
        border: 0px;
    }

    .custom-card {
        min-height: auto;
    }

    .input-container {
        margin: 5px;

        input {
            width: 35px;
        }
    }

    .img-container {
        svg {
            height: 20px;
            margin: 0px 0px 5px 5px;
        }
    }
} */