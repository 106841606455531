@import '../../../Styles/Colors.scss';

.add-edit-link-modal {
    background-color: $backgroundLightColor;
    border-radius: 20px 20px 10px 10px;

    .modal-header {
        color: $selectionColor;
        background-image: $primaryGradient;
        border-radius: 10px 10px 0px 0px;
        font-size: 20px;
        line-height: 18px;
    }

    label {
        margin: 0px;
        font-size: 14px;
    }

    .form-group {
        margin: 0px;
    }

    .clear-text {
        cursor: pointer;
        font-style: italic;
        text-decoration: underline;
        font-size: 12px;
        padding-left: 10px
    }

    .rounded-input {
        border-radius: 15px;
    }
}