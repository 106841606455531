@import '../../Styles/Colors.scss';

.login {
    height: 100%;
    display: grid;
    align-items: center;
    color: $textColor;

    .row {
        padding: 5px 0px;
    }

    .logo {
        height: 400px;
        width: 400px;
        background-image: $primaryGradient;
        margin: 50px 0px;
        display: flex;
        align-items: center;
        border: 1px solid $backgroundLightColor;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .input-container {
        height: 400px;
        width: 350px;
        display: grid;
        align-items: center;
        padding: 0px 50px;
        margin: 50px 0px;
        background-color: $backgroundLightColor;

        .form-group {
            margin-bottom: 0px;
        }

        .forgot-password {
            font-size: 12px;
            font-family: 'Roboto-Light';
            cursor: pointer;
        }

        button {
            margin-top: 5px;
        }
    }

    .login-text {
        font-size: 25px;
        font-family: 'Roboto-Medium', sans-serif !important;
    }

    label {
        margin: 0px;
    }
}


@media only screen and (max-width: 991px) {
    .login {
        .logo {
            width: 350px;
            margin: 0px;
        }

        .input-container {
            border-width: 5px;
            margin: 0px;
        }
    }
}