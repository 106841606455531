/* src/Components/Common/EmployeeCard.scss */

.employee-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    cursor: pointer;
}

.employee-card-body {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.employee-image-section {
    flex: 0 0 50px;
    margin-right: 20px;
}

.employee-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.employee-details {
    flex: 1;
    margin-right: 20px;
}

.employee-name {
    font-size: 16px;
    margin-bottom: 5px;
}


.employee-id span,
.employee-department span {
    color: #6D6D72;
    padding-left: 5px;
}

.employee-actions {
    display: flex;
    gap: 8px;
}

.btn-approval {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
}

.approve-btn {
    background-color: #28a745;
}

.deny-btn {
    background-color: #dc3545;
}


.employee-details-modal {
    width: fit-content;
    max-width: 100%;
}

.employee-modal-content {
    background-color: white;
    // border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    font-size: 14px;
    border-radius: 10px 10px 0px 0px;
}

.employee-modal-content .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
    background-image: linear-gradient(135deg, #5D74ED 0%, #3A4784 100%);
    color: white;
    font-size: 20px;
    border-radius: 10px 10px 0px 0px;
}

.employee-modal-content .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.employee-details-modal .image-spinner {
    position: absolute;
    top: 50%;
    left: 45%;
    z-Index: 1;
    width: 50px !important;
    height: 50px !important;
    border-width: 5px !important;

}

.modal-image {
    width: 400px;
    height: 400px;
    margin-bottom: 10px;
}

.modal-details {
    text-align: left;
    padding-left: 40px;
}

.modal-details p {
    margin: 5px 0;
}


.modal-header .close-btn {
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
}

.employee-updated-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: auto;
    text-align: right;
    font-size: 14px;
    line-height: 24px;
    color: #6D6D72;
    font-family: 'Roboto-Light', sans-serif !important;
    position: relative;
    max-width: 400px;
    min-width: fit-content;
}

.updated-by,
.updated-at,
.remark-div {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    position: relative;
    /* Ensure relative positioning for the tooltip */
}

.value {
    padding-left: 5px;
    display: inline-block;
    width: 200px;
    /* Adjust the width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    position: relative;
    /* Position relative to parent for tooltip */
    text-align: start;
}

/* Tooltip styling for all three items */
.tooltip {
    visibility: hidden;
    background-color: #fff;
    color: #333;
    text-align: left;
    border-radius: 4px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    max-width: 500px;
    opacity: 0;
    transition: opacity 0.3s;
    border: 1px solid #ddd;
    font-size: 12px;
    left: 50%;
    /* Center tooltip */
    transform: translateX(-50%) translateY(-10px);
    /* Center and move it up */
    top: 35px;

}

.tooltip::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ddd transparent;
    /* Arrow color */
    bottom: 100%;
    /* Position the arrow at the bottom */
}

/* Show the tooltip on hover for all three items */
.updated-by .value:hover+.tooltip,
.updated-at .value:hover+.tooltip,
.remark-div .value:hover+.tooltip {
    visibility: visible;
    opacity: 1;
}

.remark-formgroup .viewmodal-actions {
    display: flex;
    gap: 8px;
    justify-content: center;
    width: 80%;
}

.remark-formgroup .error-text {
    color: #dc3545;
    white-space: nowrap;
}

.remark-formgroup {
    padding: 20px 0;
}

.employee-details-modal .viewmodal-body {
    display: flex;
    flex-direction: row;
    padding: 10px;
    // align-items: flex-start;
}

.viewmodal-body .viewmodal-details {
    width: 500px;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    justify-content: space-between;

}

.viewmodal-body .viewmodal-details .val {
    padding-left: 5px;
    font-weight: lighter;
}

.viewmodal-body .viewmodal-details p {
    margin-bottom: 15px;
    font-weight: bolder;
}

.remark-input-box {
    width: 80%;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}