@import '../../../Styles/Colors.scss';

.base-container {
  display: flex;
  justify-content: center;  
  align-items: flex-start;  
  //height: calc(100% - 60px);
  overflow: hidden;  
  padding: 0;  
}

.base-component {
  background-color: #ffffff;
  // width: 900px;  
  width: 65%;   
  height: 100%;  
  // border-radius: 12px;  
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  
  padding: 20px;  
  // overflow-y: auto;  
  position: sticky;  
  top: 0;  
  padding-top: 0;

}
.base-component .nodata{
  text-align: center;
}


.nav-tabs {
  display: flex;
  justify-content: flex-start;  
  border-bottom: 2px solid #e0e0e0;  
  margin-bottom: 20px;  
  padding-bottom: 5px;  
  position: sticky;  
  top: 0;  
  background-color: #ffffff;  
  z-index: 10; 
}

.nav-item {
  margin-right: 15px;  
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  border: none;
  color: #3949ab !important;
}

.nav-tabs .nav-link.active::after {
  background-color: #3949ab;
  color: #3949ab;
}

.nav-tabs .nav-link {
  border: none !important;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.nav-link {
  padding: 10px 15px;  
  color: #555;  
  font-weight: 500;  
  background-color: transparent;  
  border: none;  
  transition: all 0.3s ease;  
  cursor: pointer;  
  position: relative;  

  &:hover {
    color: #3949ab;  
    border: unset;
  }

  &::after {
    content: '';  
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;  
    height: 2px;  
    background-color: transparent;  
    transition: background-color 0.3s ease;  
  }

  &.active {
    color: #3949ab;  
    border: unset;
  }
}


.container-fluid {
  padding: 0;  
}

.approval-screen {
  margin: 20px;

  .nav-tabs {
    margin-bottom: 20px;
  }

  .employee-card {
    margin-bottom: 15px;
    border: 1px solid #ddd;
  }

  .employee-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

.sidebar {
  height: 100vh;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.buttons-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center; 
  gap: 10px; 
  margin-top: 20px;
  list-style: none; 
  padding: 0; 
}

.page-item {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  margin: 0 5px; 
  padding: 8px 12px; 
  border: 1px solid #ddd; 
  border-radius: 4px; 
  cursor: pointer; 
  background-color: #f8f9fa; 
  color: #333; 
  font-weight: 500; 
  transition: all 0.3s ease; 
}

.page-item:hover {
  background-color: #e2e6ea; 
  border-color: #ccc; 
}

.page-item.active {
  background-color: #007bff; 
  color: white; 
  border-color: #007bff; 
}

.page-item.disabled {
  cursor: not-allowed; 
  color: #aaa; 
  background-color: #f8f9fa; 
  border-color: #ddd; 
}

.ellipsis {
  display: flex; 
  align-items: center; 
  padding: 8px 12px; 
  color: #999; 
}
.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 10px;
  width: 100%;
}

.search-bar .department-dropdown {
  margin-right: 15px;
  flex: 1;

}
.search-bar .input-field {
  flex: 2;
  margin-right: 15px;
}

.search-bar .search-btn {
  padding: 0px 55px !important;
  flex: 0;
}

.search-bar .form-control option {
  font-family: 'Arial', sans-serif; /* Set your desired font family */
}


.custom-dropdown {
  position: relative;
  cursor: pointer;
  width: 40% !important;
}

.search-div{
  width: 40% !important;
}

.custom-dropdown .selected-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}

.custom-dropdown::after {
  content: "▼"; /* Arrow symbol */
  font-size: 12px;
  position: absolute;
  right: 10px; /* Adjust to position at the very end */
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: transform 0.2s ease;
  padding-left: 10px;
}

.custom-dropdown.open .custom-dropdown::after {
  transform: rotate(180deg); /* Rotate arrow when dropdown is open */
}

.dropdown-body {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  z-index: 10;
}

.search-input {
  width: calc(100% - 16px);
  padding: 4px 8px;
  margin: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.options {
  max-height: 350px;
  overflow-y: auto;
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}

.dropdown-body::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.dropdown-body{
  scrollbar-width: none; /* Hide scrollbar */
}