@import '../../../Styles/Colors.scss';

.zone-data {
    display: flex;
    flex-direction: column;
    height: 100%;

    .custom-card {
        min-height: auto;

        .card-header {
            border: 0px;
        }
    }

    .title {
        font-size: 18px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 10px;
    }

    .data {
        font-size: 14px;
        line-height: 24px;
        color: $textLightColor;
        font-family: 'Roboto-Light', sans-serif !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .campus-list {

        .row {
            background-color: $backgroundLightColor;
            border-radius: .25rem;
        }

        .btn-secondary {
            margin: 5px 5px 0px 0px;
            padding: 0px 8px !important;
            min-width: 25px;
            height: 25px !important;
            line-height: 13px !important;
            font-size: 12px !important;
        }

        .btn-icon {
            padding: 0px !important;

            svg {
                height: 18px;
                width: 18px;
            }
        }
        .label-div{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .label-div .metaLabel{
            padding:0 5px;
            font-weight: 700;
        }
        .btn-div{
            display: flex;
           justify-content: flex-end;
        }
        .width-div{
            width: 14px;
        }
    }
}