@font-face {
  font-family: "Roboto-Light";
  src: url("./Fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./Fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./Fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./Fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "GeezaPro";
  src: url("./Fonts/Geeza Pro Regular.ttf");
}

.RobotoLight {
  font-family: "Roboto-Light";
}

.RobotoRegular {
  font-family: "Roboto-Regular";
}

.RobotoBold {
  font-family: "Roboto-Bold";
}

.RobotoMedium {
  font-family: "Roboto-Medium";
}

.GeezaProRegular {
  font-family: "GeezaPro", "Roboto-Regular";
}

.GeezaProBold {
  font-family: "GeezaPro-Bold"
}