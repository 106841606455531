.modal-dialog {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0px auto !important;
    padding: 50px 0px;
}

.modal-content {
    margin-bottom: 20px;
    background-color: transparent !important;
    border: 0px !important;
}