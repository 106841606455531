@import '../../Styles/Colors.scss';

.app-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.app {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
    background-color: $backgroundColor;
    overflow: auto;
}