@import '../../../Styles/Colors.scss';

.custom-list {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $backgroundLightColor;
    border-radius: 5px;
    font-size: 14px;

    .list-group-item {
        width: 100%;
        border: 0px;

        span {
            height: 20px;
        }

        .checkbox-container {
            height: 30px;
            min-width: 35px;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;

            div {
                min-width: 35px;
                text-align: center;
            }
        }

        .icons-container {
            display: flex;
            align-items: center;

            span {
                margin-left: 40px;
            }

            span:first-child {
                margin-left: 10px;
            }
        }
    }

    .active {
        background-color: $primaryColor !important;
    }

    .editing {
        background: $editSelectionColor !important;
        border: 0px;
        padding: 4px 20px;

        .custom-input-group {
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: hidden;

            .form-group {
                margin-bottom: 0px;

                input {
                    box-shadow: none;
                }

                .invalid-feedback {
                    margin: 0px;
                }
            }

            .form-group:first-child {
                margin-bottom: 2px;
            }
        }
    }
}

/*.custom-list {
    max-height: 100%;

    .card-body {
        max-height: 400px;
        overflow: auto;
    }

    .fa-w-11 {
        height: 20px;
        width: 20px;
        margin: 0px 0px 5px 10px;
    }

    .list-group-item {
        padding: 10px;

        span {
            height: 20px;
        }

        .checkbox-container {
            display: flex;
            height: 30px;
            min-width: 35px;
            flex-direction: column;
            align-items: center;
            font-size: 10px;
            line-height: 12px;
            margin-left: 10px;
        }

        .image-sm {
            height: 20px;
            width: 20px;
        }
    }

    .list-group-item.active {
        background-image: $primaryColor;
        border-color: $primaryColor;
    }

    .list-group-item:first-child {
        border-radius: 0px;
    }
} */