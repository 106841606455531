@import '../../Styles/Colors.scss';

.header {
    height: 60px;
    min-height: 60px;
    font-size: 15px;
    background-color: $backgroundLightColor;
    border-bottom: 1px solid $borderColor;
}

.dropdown-menu {
    padding: 10px 0px 2px !important;
}

.navbar {
    height: 100%;
    width: 100%;
    background-color: transparent;
    padding: 0px 16px !important;
    font-size: 14px;

    ul {
        height: 100%;

        li {
            display: flex;
            align-items: center;

            a {
                height: 100%;
                padding: 0px 30px;
                display: flex;
                align-items: center;
            }
        }
    }

    a,
    a:hover {
        color: $textColor;
        text-decoration: none;
    }

    li {
        a.active {
            color: $selectionColor;
            background-image: $primaryGradient;
        }
    }

    .circular-btn {
        height: 40px !important;
        width: 40px;
        min-width: 40px !important;
        border-radius: 50% !important;
        padding: 0px !important;
        text-align: center;
        border: 0px;
        background: $backgroundColor !important;
        color: $textColor !important;
        font-size: 14px !important;
    }

    .circular-div {
        height: 60px;
        width: 60px;
        line-height: 60px;
        border-radius: 50%;
        font-size: 20px;
        background: $backgroundColor !important;
        margin: 0px auto;
        text-align: center;

    }

    .user-name {
        margin: 5px;
        font-size: 14px;
    }

    .dropdown-menu {
        // width: 200px;

        .dropdown-divider {
            margin: 2px 0px;
        }
    }

    .custom-dropdown-toggle {
        cursor: pointer;
        &:hover {
            text-decoration: none; /* Remove underline if needed */
        }
    }
    .submenu-item{
        padding: unset !important;
    }
    .dropdown-item{
        font-size: 14px;
        padding: unset;
        
    }
    .dropdown-item.active-submenu{
        color:#3A4784;

    }
    a.dropdown-item.submenu-item{
        padding: .25rem 1.5rem !important;

    }
}